import styled from 'styled-components';
import theme from '../../assets/theme/theme'

const NavigationWrap =  styled.header`
    background: ${props => props.theme.NavBG};
    position: absolute;
    width: 100%;
    z-index: 9999;
    top: 0;
    transition: all 0.3s ease 0s;
    border-bottom: 1px solid transparent;
  .navbar-wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobile-menu-icon {
    display: none;
    color: ${theme.bodyColor};
    font-size: 24px;
  }
  .nav__button{
    transition: all 0.3s ease-in;
  }
  /*Drop down style*/ 
  .dropdown{
    background: #fff;
    visibility: hidden;
    opacity: 0;
    min-width: 200px;
    border: 1px solid #f5f5f5;
    position: absolute;
    margin-top: 1rem;
    left: 20px;
    padding: 15px 0px;
    display: none;
    box-shadow: 0px 30px 70px 0px rgba(137,139,142,0.15);
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    transition: all 0.3s ease-in-out;
  }
  ul li:hover > ul,
  ul li ul:hover {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    display: block;
  }
  
  nav {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
    }
    li {
      position: relative;
      padding: 28px 0px 28px 40px;

      .dropdown {
        li {
          padding: 0;

          a {
            color: #fff;
            display: block;
            padding: 5px 15px;

            &:hover {
              color: ${props => props.theme.menuHoverColor ? props.theme.menuHoverColor : '#fff'} !important;
            }

            &:before {
              content: none;
            }
          }
        }
      }

      a, .nav-active {
        position: relative;
        color: #fff !important;
        font-size: 15px;
        transition: 450ms all;
        padding-bottom: 5px;
        cursor: pointer;

        &:before {

          position: absolute;
          left: 0;
          bottom: 0;
          width: 20px;
          height: 1px;
          opacity: 0;
          background: ${props => props.theme.menuHoverColor ? props.theme.menuHoverColor : '#fff'};
          transition: 450ms all;
        }

        &:hover {
          color: ${props => props.theme.menuHoverColor ? props.theme.menuHoverColor : '#fff'};

          &:before {
            opacity: 1;
          }
        }
      }
    }  
    }
  .logo{
    transition: all 0.8s cubic-bezier(0.3, 0.8, 0.2, 1) 0s;
    img{
      width: 135px;
      height: 75px;
    }
  }
  &.scrolled{ 
    box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 30px;
    background: transparent;
    border-color: ${props => props.theme.NavBGSolid};
    .navbar-wrap{
      border-color: transparent
    }
    .logo{
        width: 100px;
    } 
    .nav{
      li{
        padding: 14px 0px 14px 40px;
        a{ 
          &:hover{
            color: ${props => props.theme.menuHoverColor ? props.theme.menuHoverColor : '#fff'};
          }
        }
      }
      li{
        .dropdown{
          li{
            padding: 0;
          }
        }
      }
    }
  }
  .joinwaitlistLink {
    display: none;
  }

  @media only screen and (max-width: 912px) {
    height: auto;
    min-height: 50px;
    padding: 15px 20px;
    background: transparent;

    .joinwaitlistButton {
      display: none;
    }
    .joinwaitlistLink {
      display: inline-block;
    }

    .nav__button{
      
      color: ${props => props.theme.white};
      transition: all 0.3s ease-in;
      &:hover,
      &:focus{
        border: 2px solid white;
        outline: none;
        color: white;
      }
    }
    
    &.scrolled{
        padding: 8px 20px;
        border-color: transparent;
        .nav .collapsed {
          li{
            padding: 0;
            a{
              padding: 8px 10px;
              display: block;
            }
          }
          li+li{
            padding-left: 0;
          }
        } 
    }
    .has-dropdown{
      a{
        position: relative;
        &:before{
          content: '';
          
        }
      }
    }
    .dropdown{
      position: relative;
      left: 0;
      margin-top: 0;
      box-shadow: none;
    }
    .navbar-wrap{
        border-color: transparent;
        display: block;
        position: relative;
    }
    .logo {
      width: 100%;
      display: block;
      margin: 7px 0 0 0;
      img{width: 110px;}
    }
    .mobile-menu-icon {
      display: inline-block;
      position: absolute;
      top: 21px;
      right: 3px;
      cursor: pointer;
    }
    .nav{
        width: 100%;
        .mobile-menu-icon{
          color: white;
        }
      .collapsed {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        transition: 450ms all ease-in-out;
        overflow: hidden;
        max-height: 0;
        
        &.is-expanded {
          
          overflow-y: auto;
          max-height: 250px; /* approximate max height */
          outline: 1px solid rgb(99, 171, 244);
          background-color: white;
          border-radius: 10px;
        }
        li {
          padding: 0;
          margin: 0px 0px;
          width: 100%;
          .dropdown{
            li{
              a {
                color: #3d434d  ;
              }
            }
          }
          a {
            padding: 8px 10px 8px 30px;
            display: block;
            color: #3d434d !important;
            &:before{
              content: '';
              position: absolute;
              left : 30px;
              bottom:10px;
              width: 20px;
              height: 1px;
              opacity: 0;
              background: ${props => props.theme.bodyColor ? props.theme.bodyColor : '#3e3f40'};
              transition: 450ms all;
            }
            &:hover{
              color: ${props => props.theme.bodyColor ? props.theme.bodyColor : '#3e3f40'};
              &:before{
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

`

export default NavigationWrap;
