import React from 'react';
import styles from './CardTile.module.css';
import classNames from "classnames";
import {
    FaGooglePlay,
    FaApple,
    FaProjectDiagram,
    FaSquareRootAlt,
    FaSuperscript,
    FaSearchDollar,
    FaPoll,
    FaSyncAlt,
    FaStopwatch
    }  from "react-icons/fa"
import {Text} from '../index'
import theme  from '../../assets/theme/theme'

const backgroundColorOptions = {
    card1: "radial-gradient(#1fe4f5, #3fbafe)",
    card2: "radial-gradient(rgb(157, 203, 248), rgb(99, 171, 244))",
    card3: "radial-gradient(rgb(157, 203, 248), rgb(11, 124, 237))",
}


function CardTile({title, cardDescription, faIcon}) {
    return (
        <div className={classNames(styles.card, styles.card3)}>

            <div className={styles.cardContent}>
                <Text
                    color={theme.white}
                    fontWeight="bold"
                    fontSize="normal"
                    className={styles.title}
                >
                    {title ? title : "Accounting Decentralized"}
                </Text>
                <Text
                    color={theme.white}
                    marginBottom="0"
                >
                    {
                        cardDescription
                            ? cardDescription
                            : 'Immutable data means zero financial frauds and\n' +
                        'decentralized architecture provides security'
                    }
                </Text>
            </div>
            <div className={styles.card__icon}>
                {
                  faIcon === "FaProjectDiagram" ?  <FaProjectDiagram /> :
                      faIcon === "FaSquareRootAlt" ? <FaSquareRootAlt /> :
                          faIcon === "FaSuperscript" ? <FaSuperscript /> :
                              faIcon === "FaPoll" ? <FaPoll /> :
                                  faIcon === "FaSyncAlt" ? <FaSyncAlt /> :
                                      faIcon === "FaStopwatch" ? <FaStopwatch /> :
                                        faIcon === "FaSearchDollar" ? <FaSearchDollar /> : <FaApple />



                }
            </div>
        </div>
    );
}

export default CardTile;
