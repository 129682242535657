import React from 'react';
import colors from '../../colors';
import classNames from 'classnames';
import styles from './Section.module.css';
import Fade from "react-reveal/Fade";
import {SectionTitle} from "../../reusecore/SectionTitle";
import Heading from "../../reusecore/Heading";
import {TimelineSectionWrapper} from "../../sections/07-TimelineSection/timeline.style";
import styled from "styled-components";

// const Props = {
//   children?: React.ReactNode;
//   className?: string;
//   contentContainerClassName?: string;
//   contentContainerStyle?: any;
//   fluidContainer?: boolean;
//   headerStyle?: any;
//   id?: string;
//   style?: any;
//   title?: ReactNode | string;
//   hideTitleDesktop?: boolean;
//   removedBottomMargin?: boolean;
// }

const TeamSectionTitleWrapper = styled.section`

  padding-bottom: 50px;
  padding-top: 50px;
  text-align: center;

`;

const Section = ({
  children,
  className = '',
  contentContainerClassName = '',
  contentContainerStyle = {},
  fluidContainer = false,
  headerStyle = {},
  id,
  style = {},
  title,
  hideTitleDesktop,
  removedBottomMargin,
}) => {
  const headlineDecoratorStyle = {
    backgroundColor: colors.LIGHT_BLUE,
    ...headerStyle,
  };

  let containerClassName = styles.container;
  if ((contentContainerClassName).length > 0) {
    containerClassName = classNames(
      styles.container,
      contentContainerClassName
    );
  }

  const marginBottomProp = removedBottomMargin ? styles.noMargin : '';

  const containerStyle = fluidContainer
    ? styles.fluidContainer
    : styles.baseContainer;

  return (
    <div className={className} id={id} style={style}>
      <div
        className={classNames(containerClassName, containerStyle)}
        style={contentContainerStyle}
      >
        <TeamSectionTitleWrapper>
            <SectionTitle className="sectionHeading">

              <Fade bottom>
                <Heading>
                  {title}
                </Heading>
              </Fade>
            </SectionTitle>
      </TeamSectionTitleWrapper>
        {children}
      </div>
    </div>
  );
};

export default Section;
