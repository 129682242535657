import React, { ReactNode } from 'react';
import Text from '../Text/Text';
import classNames from 'classnames';
import styles from './Button.module.css';

interface IProps {
  children?: string | ReactNode;
  disabled?: boolean;
  loading?: boolean;
  letterSpacing?: string;
  onPress?: () => any;
  small?: boolean;
  trackingId?: string;
  url?: string;
  className?: string;
  color?: string;
}

// @ts-ignore
export default ({
  children,
  disabled = false,
  onPress = () => {},
  small = false,
  className,
}: IProps) => {
  const heightProp = small ? '100%' : '40px';
  const paddingProp = small ? '5px 14px' : styles.buttonStyle;

  return (
    <div
      className={classNames(
        styles.container,
        paddingProp,
        className
      )}
      style={{
        padding: paddingProp,
        height: heightProp,
      }}
      onClick={() => {
        if (!disabled) {
          onPress();
        }
      }}
    >
      <span className={styles.textStyling}>{children}</span>
    </div>
  );
};
