import React, { ReactNode } from 'react';
import Text from '../Text/Text';
import classNames from 'classnames';
import styles from './TransparentButton.module.css';

interface IProps {
  children?: string | ReactNode;
  disabled?: boolean;
  loading?: boolean;
  letterSpacing?: string;
  onPress?: () => any;
  small?: boolean;
  trackingId?: string;
  url?: string;
  className?: string;
}

// @ts-ignore
export default ({
  children,
  disabled = false,
  loading = false,
  letterSpacing = '0',
  onPress = () => {},
  small = false,
  className,
}: IProps) => {
  const heightProp = small ? '100%' : '40px';
  const paddingProp = small ? '5px 14px' : styles.buttonStyle;
  const backgroundProp = disabled
    ? styles.backgroundImageDisabled
    : 'transparent';

  return (
    <div
      className={classNames(
        styles.container,
        backgroundProp,
        paddingProp,
        className
      )}
      style={{
        padding: paddingProp,
        height: heightProp,
      }}
      onClick={() => {
        if (!disabled) {
          onPress();
        }
      }}
    >
      <Text
        align="center"
        className={styles.textColor}
        display="table-cell"
        fontSize="xsmall"
        fontWeight="bold"
        letterSpacing={letterSpacing}
        style={{
          userSelect: 'none',
          WebkitUserSelect: 'none',
        }}
      >
        {loading ? 'Loading...' : children}
      </Text>
    </div>
  );
};
