import React from "react"
import { Link } from "gatsby"
import { Link as OnepageLink } from "react-scroll"
import {List, ListItem} from 'reusecore/List'
import {TransparentButton} from '../../../components/index'
import ReactTooltip from 'react-tooltip';
const ScrollspyMenu = ({ menuItems, ...props }) => {
  const addAllClasses = [""]
  if (props.className) {
    addAllClasses.push(props.className)
  }
  return (
    <List className={addAllClasses.join(" ")}>
      {menuItems.map((menu, index) => {

          return (
                    <ListItem
                      key={index}
                      className={
                        menu.subItems !== undefined ? "nav-item has-dropdown" : "nav-item"
                      }
                    >
                      <OnepageLink
                        activeClass="nav-active"
                        to={menu.path}
                        spy={true}
                        smooth={true}
                        offset={parseInt(menu.offset)}
                        duration={700}
                      >
                        {menu.name}
                      </OnepageLink>
                      {menu.subItems !== undefined && (
                        <List key={index} className="dropdown">
                          {menu.subItems.map((subItem, i) => (
                            <ListItem key={i}>
                              <Link to={subItem.path}>{subItem.name}</Link>
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </ListItem>
          )
      })}
            <ListItem
                className="nav-item"
                data-for="main"
                data-tip="Coming soon..."
            >
                <a>Token</a>
            </ListItem>
        <ListItem
            className="nav-item"
            data-for="main"
            data-tip="Coming soon..."
        >
            <a>Trippl Protocol</a>
        </ListItem>
            <ReactTooltip
                id="main"
                place="bottom"
                type="info"
                effect="solid"
            />
        <ListItem
            className="nav-item"
        >
            <a href="https://tripplymain.gitbook.io/tripply/" target="_blank">Whitepaper</a>
        </ListItem>
        <ListItem
            className="nav-item joinwaitlistLink"
        >
            <Link
                to="#contact_section"
                spy={true}
                smooth={true}
                offset={60}
                duration={700}
            >
                Join Waitlist
            </Link>
        </ListItem>
          <ListItem className="joinwaitlistButton">
              <Link
                  to="#contact_section"
                  spy={true}
                  smooth={true}
                  offset={60}
                  duration={700}
              >
            <TransparentButton className="nav__button">Join Waitlist</TransparentButton>
              </Link>
          </ListItem>

    </List>
  )
}

export default ScrollspyMenu
