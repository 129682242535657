import React from "react"
import Text from '../Text/Text'
import styled from "styled-components"

import BreadcrumbBG from "assets/images/blog/breadcrumb/blog-breadcrumb.jpg"

const UsecasesHeader = ({ title }) => {
    return (
        <PageHeaderWrapper>
            <div className="page-header">
                <h1 >{title}</h1>
                <div className="description">
                    <Text fontSize="small" fontWeight="600" color="white">
                        Our technology has the capacity to reshape the way
                        business is done today, improving efficiency through
                        digitization of existing accounting processes and opening the door to DeFi.
                    </Text>
                </div>
            </div>
        </PageHeaderWrapper>
    )
}

const PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 auto 130px;
  overflow: hidden;
  z-index: 1;
  background: url(${BreadcrumbBG}) no-repeat;
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(200, 56, 231);
    background: linear-gradient(
      0deg,
      rgba(200, 56, 231, 1) 0%,
      rgba(31, 42, 213, 1) 100%
    );
    content: "";
    opacity: 0.65;
  }
  .page-header {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 272px 0px 220px;
    position: relative;
    z-index: 99;
    h1 {
      font-size: 80px;
      line-height: 57px;
      margin: 0 0 50px 0;
    }

    .description {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
    }
  }
  @media only screen and (max-width: 480px) {
    .page-header {
      padding: 160px 0px 100px;
      h1 {
        font-size: 30px;
        line-height: 44px;
      }
    }
  }
`
export default UsecasesHeader
