import React, { ReactNode, StatelessComponent } from 'react';
import classNames from 'classnames';
import styles from './Text.module.css';

export type Display =
  | 'flex'
  | 'inline'
  | 'inline-block'
  | 'block'
  | 'table-cell';

export type Size = 'tiny' | 'xsmall' | 'small' | 'normal' | 'large' | 'xlarge';

interface IProps {
  align?: 'center' | 'right' | 'left';
  children?: ReactNode;
  className?: string;
  color?: any;
  display?: Display;
  fontSize?: Size;
  fontWeight?: 'normal' | 'bold' | string;
  id?: string;
  letterSpacing?: string;
  lineHeight?: string;
  marginBottom?: any;
  marginTop?: any;
  onClick?: (event: any) => any | void;
  style?: any;
}

const Text: StatelessComponent<IProps> = ({
  align,
  children,
  className,
  color,
  display,
  fontSize,
  fontWeight,
  id,
  letterSpacing,
  lineHeight,
  marginBottom,
  marginTop,
  onClick,
  style,
}: IProps) => {
  const alignTextProp = align ? align : 'left';
  const fontWeightProp = fontWeight ? fontWeight : 'normal';
  const displayProp = display ? display : 'block';
  const fontSizeProp = fontSize ? fontSize : 'normal ';
  const marginBottomProp =
    marginBottom ||
    (['flex', 'table-cell'].indexOf(display) > -1 ? '0' : '1rem');
  const marginTopProp = marginTop;

  const stylingClasses = classNames(
    styles.textStyling,
    styles[alignTextProp],
    styles[fontSizeProp],
      className
  );

  return (
    <span
      className={stylingClasses}
      id={id}
      onClick={onClick}
      style={{
        ...style,
        lineHeight,
        letterSpacing,
        marginBottom: marginBottomProp,
        marginTop: marginTopProp,
        fontWeight: fontWeightProp,
        display: displayProp,
      }}
    >
      {children}
    </span>
  );
};

export default Text;
