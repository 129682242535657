const theme = {
    black: '#333333',
    white: '#ffffff',
    primaryColor: '#004085',
    primaryHoverColor: '#cce5ff',
    secondaryColor: '#3e3f40',
    headingColor: '#3e3f40',
    bodyColor: '#3e3f40',
    linkColor: '#1F2AD5',
    NavBG: 'transparent',
    NavBGSolid: '#290E59',
    menuHoverColor: '#ffffff',
    usecasesBackgrond: '#363f9f',
    brandViolletDarker: 'rgb(142, 64, 212)',
    brandColorDarkestBlue: 'rgba(37, 42, 213, 1)', // #252bd5
    brandColorDeepBlue: 'rgb(11, 124, 237)', // #0b7ced
    brandColorBlue: 'rgb(99, 171, 244)', // #63abf4
    brandColorLightBlue: 'rgb(64, 152, 241)', // #4098f1
    brandColorLightestBlue: 'rgb(157, 203, 248)', // #9dcbf8
};
export default theme;

