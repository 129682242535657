import React, {  useEffect, useRef, useState } from 'react';
import  IconLinkedIn  from  '../../assets/icons/sharing/linkedIn.png';
import styles from './TeamMemberTile.module.css';
import classNames from 'classnames';
import {
  addWindowEventListener,
  removeWindowEventListener,
} from '../../utils/layout/index';
import {number} from "prop-types";

console.log(IconLinkedIn, 'IconLinkedIn')
export default ({ children, image, linkedIn }) => {
    console.log(image, 'image')
  const divRef = useRef(null);
    console.log(divRef?.current?.clientWidth, 'divRef')
  // const [imgHeight, setImgHeight] = useState<null | number>(null);
  // console.log(imgHeight, 'imgHeight')
  // useEffect(() => {
  //   const applyNewHeight = () => {
  //     if (divRef.current && divRef.current.clientWidth) {
  //       setImgHeight(divRef.current.clientWidth);
  //     }
  //   };
  //   if (divRef.current && divRef.current.clientWidth) {
  //     addWindowEventListener('resize', applyNewHeight);
  //   }
  //
  //   //clean up function
  //   return () => {
  //     // remove the event listener when the component unmounts on route change
  //     // https://stackoverflow.com/questions/61274365/allow-component-to-detect-route-change-in-gatsby
  //     removeWindowEventListener('resize', applyNewHeight);
  //   };
  // }, []);

  // <div ref={divRef} className={styles.container}>

  // {image && <img style={{ height: imgHeight }} src={image} />}
  return (
    <a href={linkedIn} target="_blank">
      <div  className={styles.container}>
        {image && <img src={image} />}

        <div className={styles.childrenContainer}>
          <div className={styles.nameContainer}>{children}</div>
        </div>

        {linkedIn && (
          <div className={classNames(styles.iconContainer, styles.smallCard)}>
              <img src={IconLinkedIn} className={styles.linkedInIconStyle} />
          </div>
        )}
      </div>
    </a>
  );
};
