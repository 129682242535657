export const globalWindow = typeof window !== 'undefined' && window;

const addWindowEventListener = (
  eventName,
  functionName,
) => {
  globalWindow.addEventListener(eventName, functionName);
};

export default addWindowEventListener;
