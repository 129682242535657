const Data = {
  menuItems: [
    // {
    //   name: "Home",
    //   path: "/",
    //   subItems: [
    //     {
    //       name: "Home Two",
    //       path: "/home-two",
    //     },
    //     {
    //       name: "Home Three",
    //       path: "/home-three",
    //     },
    //     {
    //       name: "Home Slider",
    //       path: "/home-slider",
    //     }
    //   ],
    // },
    // {
    //   name: "Token",
    //   path: "token",
    //   offset: "-100",
    // },
    // {
    //   name: "Whitepaper",
    //   path: "https://tripplymain.gitbook.io/tripply/",
    //   offset: "-60",
    // },
    // {
    //   name: "Blog",
    //   path: "/blog-grid",
    //   subItems: [
    //     {
    //       name: "Blog Grid",
    //       path: "/blog-grid",
    //     },
    //     {
    //       name: "Blog List",
    //       path: "/blog-list",
    //     },
    //     {
    //       name: "Blog Full",
    //       path: "/blog-full",
    //     },
    //     {
    //       name: "Blog Single",
    //       path: "/blog-single",
    //     },
    //   ],
    // },
    // {
    //   name: "Contact",
    //   path: "contact",
    //   offset: "-70",
    // },
  ],
}
export default Data
